import React from "react";
import { lazy, Suspense } from "react";
import { Security, useOktaAuth } from "@okta/okta-react";
import OktaAuth from "@okta/okta-auth-js";
import {
  AlertProvider,
  AlertsFromProvider,
  BCR,
  Loading,
  LoadingFromProvider,
  LoadingProvider,
  Navy,
} from "best-common-react";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./contexts/UserContext";
import { MetadataProvider } from "./contexts/MetadataContext.tsx";
import { DropdownsProvider } from "./contexts/DropdownsContext.tsx";

/* Environment Variables */
const AuthenticatedApp = lazy(() => import("./modules/AuthenticatedApp"));
const UnauthenticatedApp = lazy(() => import("./modules/UnauthenticatedApp"));

const App = () => {
  const { authState } = useOktaAuth();

  return (
    <BCR theme={Navy}>
      <Suspense fallback={<Loading type="player" />}>
        <LoadingProvider>
          <AlertProvider>
            <MetadataProvider>
              <DropdownsProvider>
                <UserProvider>
                  <BrowserRouter>
                    <AlertsFromProvider />
                    <LoadingFromProvider />
                    {authState?.isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
                  </BrowserRouter>
                </UserProvider>
              </DropdownsProvider>
            </MetadataProvider>
          </AlertProvider>
        </LoadingProvider>
      </Suspense>
    </BCR>
  );
};

const oktaAuth = new OktaAuth({
  clientId: import.meta.env.VITE_OKTA_CLIENT_ID,
  issuer: import.meta.env.VITE_OKTA_ISSUER,
  redirectUri: import.meta.env.VITE_OKTA_REDIRECT_URI,
  scopes: ["openid", "profile", "email"],
  pkce: true,
  tokenManager: {
    expireEarlySeconds: 60
  }
});

const SecureApp: React.FC = () => {
  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string): Promise<void> => {
    window.location.replace(originalUri);
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <App />
    </Security>
  );
};

export default SecureApp;
