import axios, { AxiosInstance } from "axios";
import { UserDetails } from "../types/authentication.ts";
import { EmailListFilters, EmailListRow, EmailListUploadDTO } from "../types/emailList.ts";
import { MarketingCampaignRow, NewMarketingCampaign } from "../types/marketing-campaign.ts";
import { SourceCode, Team } from "../types/metadata.ts";
import { BullseyeQuery, RunQueryResponse } from "../types/query.ts";

const BASE_URL = import.meta.env.VITE_SERVICES_URL;

export const AuthenticatedClient: AxiosInstance = axios.create({
  baseURL: `${BASE_URL}/api`
});

export const getUserInfo = async (): Promise<UserDetails> => (await AuthenticatedClient.get(`/user/info`)).data.entity;

export const searchEmailLists = async (filters: EmailListFilters): Promise<EmailListRow[]> =>
  (await AuthenticatedClient.post("/email-lists", filters)).data;

export const uploadEmailList = async (body: EmailListUploadDTO): Promise<void> =>
  await AuthenticatedClient.post("/email-lists/upload", body);

export const searchMarketingCampaigns = async (): Promise<MarketingCampaignRow[]> =>
  (await AuthenticatedClient.get("/marketing-campaigns")).data;

export const createMarketingCampaign = async (body: NewMarketingCampaign): Promise<void> =>
  (await AuthenticatedClient.post("/marketing-campaigns", body)).data;

export const getTeams = async (): Promise<Team[]> => (await AuthenticatedClient.get("/metadata/teams")).data;

export const getSourceCodes = async (): Promise<SourceCode[]> =>
  (await AuthenticatedClient.get("/metadata/source-codes")).data;

export const getQueries = async (): Promise<BullseyeQuery[]> => (await AuthenticatedClient.get("/queries")).data;

export const runQuery = async (query: string): Promise<RunQueryResponse> =>
  (await AuthenticatedClient.post("/queries/run", { query })).data;