import React, { ReactNode, useEffect, useState } from "react";
import { ValueOpt } from "best-common-react";
import { Team } from "../types/metadata.ts";
import { useMetadata } from "./MetadataContext.tsx";
import { CsvHeaderAttributes } from "../constants/csv.ts";
import { MarketingCampaignTypes, Reoccurrences } from "../constants/marketingCampaign.ts";
import { Recurrence } from "../types/marketing-campaign.ts";

type DropdownsContextType = {
  teamOptions: ValueOpt<Team>[];
  csvHeaderAttributesOptions: ValueOpt<string>[];
  campaignTypeOptions: ValueOpt<string>[];
  recurrenceOptions: ValueOpt<Recurrence>[];
};

const DropdownsContext = React.createContext<DropdownsContextType | undefined>(undefined);

type DropdownsProviderProps = {
  children?: ReactNode;
};

const DropdownsProvider: React.FC<DropdownsProviderProps> = ({ ...props }) => {
  const { teams } = useMetadata();
  const [teamOptions, setTeamOptions] = useState<ValueOpt<Team>[]>([]);
  const [csvHeaderAttributesOptions, setCsvHeaderAttributesOptions] = useState<ValueOpt<string>[]>([]);
  const [campaignTypeOptions, setCampaignTypeOptions] = useState<ValueOpt<string>[]>([]);
  const [recurrenceOptions, setRecurrenceOptions] = useState<ValueOpt<Recurrence>[]>([]);

  useEffect(() => {
    setTeamOptions(teams.map(team => ({ value: team, label: team.name })) as ValueOpt<Team>[]);
  }, [teams]);

  useEffect(() => {
    setCsvHeaderAttributesOptions(
      CsvHeaderAttributes.map(({ key, displayName }) => ({ value: key, label: displayName })) as ValueOpt<string>[]
    );

    setCampaignTypeOptions(MarketingCampaignTypes.map(value => ({ value, label: value })) as ValueOpt<string>[]);

    setRecurrenceOptions(Reoccurrences.map(value => ({ value, label: value })) as ValueOpt<Recurrence>[]);
  }, []);

  return (
    <DropdownsContext.Provider
      value={{ teamOptions, csvHeaderAttributesOptions, campaignTypeOptions, recurrenceOptions }}
      {...props}
    />
  );
};

const useDropdowns = (): DropdownsContextType => {
  const context: DropdownsContextType | undefined = React.useContext(DropdownsContext);
  if (context === undefined) {
    throw new Error(`useDropdowns must be used within a DropdownsProvider`);
  }
  return context;
};

export { DropdownsProvider, useDropdowns };
