import React, { ReactNode, useEffect } from "react";
import { SourceCode, Team } from "../types/metadata.ts";
import { getLocalStorageItem, setLocalStorageItem } from "best-common-react";
import { LocalStorageConstants } from "../constants/local-storage.ts";
import { getTeams, getSourceCodes } from "../api/AuthenticatedClient.ts";

type MetadataContextType = {
  teams: Team[];
  sourceCodes: SourceCode[];
};

const MetadataContext = React.createContext<MetadataContextType | undefined>(undefined);

type MetadataProviderProps = {
  children?: ReactNode;
};

const MetadataProvider: React.FC<MetadataProviderProps> = ({ ...props }) => {
  const [teams, setTeams] = React.useState<Team[]>([]);
  const [sourceCodes, setSourceCodes] = React.useState<SourceCode[]>([]);

  const fetchTeams = async (refresh: boolean) => {
    try {
      const localStorage: Team[] = getLocalStorageItem(LocalStorageConstants.TEAMS) as Team[];
      if (localStorage !== null && !!localStorage && !refresh) {
        setTeams(localStorage);
      } else {
        const result: Team[] = await getTeams();
        setTeams(result);
        setLocalStorageItem(LocalStorageConstants.TEAMS, result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchSourceCodes = async (refresh: boolean) => {
    try {
      const localStorage: SourceCode[] = getLocalStorageItem(LocalStorageConstants.SOURCE_CODES) as SourceCode[];
      if (localStorage !== null && !!localStorage && !refresh) {
        setSourceCodes(localStorage);
      } else {
        const result: SourceCode[] = await getSourceCodes();
        setSourceCodes(result);
        setLocalStorageItem(LocalStorageConstants.SOURCE_CODES, result);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchTeams(false).catch(console.error);
    fetchSourceCodes(false).catch(console.error);
  }, []);

  return <MetadataContext.Provider value={{ teams, sourceCodes }} {...props} />;
};

const useMetadata = (): MetadataContextType => {
  const context: MetadataContextType | undefined = React.useContext(MetadataContext);
  if (context === undefined) {
    throw new Error(`useMetadata must be used within a MetadataProvider`);
  }
  return context;
};

export { MetadataProvider, useMetadata };
