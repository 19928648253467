import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

// Import best-common-react stylesheet
import "best-common-react/lib/styles/bcr.css";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <App />
  </StrictMode>
);
