import { HeaderAttribute } from "../types/csv";

export const CsvHeaderAttributes: HeaderAttribute[] = [
  { key: "email", displayName: "E-mail Address" },
  { key: "salutation", displayName: "Salutation" },
  { key: "fname", displayName: "First Name" },
  { key: "lname", displayName: "Last Name" },
  { key: "fullname", displayName: "Full Name" },
  { key: "birthdate", displayName: "Birth Date" },
  { key: "address1", displayName: "Address 1" },
  { key: "address2", displayName: "Address 2" },
  { key: "city", displayName: "City" },
  { key: "state", displayName: "State" },
  { key: "zip", displayName: "Zip Code" },
  { key: "country", displayName: "Country" },
  { key: "company", displayName: "Company" },
  { key: "hometel", displayName: "Home Telephone" },
  { key: "worktel", displayName: "Work Telephone" },
  { key: "attribute1", displayName: "Attribute 1" },
  { key: "attribute2", displayName: "Attribute 2" },
  { key: "attribute3", displayName: "Attribute 3" },
  { key: "attribute4", displayName: "Attribute 4" },
  { key: "attribute5", displayName: "Attribute 5" },
  { key: "attribute6", displayName: "Attribute 6" },
  { key: "attribute7", displayName: "Attribute 7" },
  { key: "attribute8", displayName: "Attribute 8" },
  { key: "attribute9", displayName: "Attribute 9" },
  { key: "attribute10", displayName: "Attribute 10" },
  { key: "attribute11", displayName: "Attribute 11" },
  { key: "attribute12", displayName: "Attribute 12" },
  { key: "attribute13", displayName: "Attribute 13" },
  { key: "attribute14", displayName: "Attribute 14" },
  { key: "attribute15", displayName: "Attribute 15" },
  { key: "attribute16", displayName: "Attribute 16" },
  { key: "attribute17", displayName: "Attribute 17" },
  { key: "attribute18", displayName: "Attribute 18" },
  { key: "gender", displayName: "Gender" },
  { key: "passwd", displayName: "Password" },
  { key: "login", displayName: "Login" },
  { key: "url", displayName: "URL" },
  { key: "promotioncode", displayName: "Promotion Code" },
  { key: "accountnum", displayName: "Account Number" },
  { key: "comments", displayName: "Comments" },
  { key: "sth_type", displayName: "Season Ticket Holder Type" }
];
