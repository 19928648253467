import React, { ReactNode, useEffect, useState } from "react";
import { UserDetails, UserRole } from "../types/authentication.ts";
import { useOktaAuth } from "@okta/okta-react";
import { AuthenticatedClient, getUserInfo } from "../api/AuthenticatedClient.ts";

type UserContextType = {
  userDetails?: UserDetails;
  roles?: UserRole[];
  logout: () => void;
};

const UserContext = React.createContext<UserContextType | undefined>(undefined);

type UserProviderProps = {
  children?: ReactNode;
};

const UserProvider: React.FC<UserProviderProps> = ({ ...props }) => {
  const [userDetails, setUserDetails] = useState<UserDetails>();
  const [roles, setRoles] = useState<UserRole[]>();
  const { authState } = useOktaAuth();

  const getToken = () => {
    if (authState) {
      return {
        accessToken: authState.accessToken?.accessToken,
        idToken: authState.idToken?.idToken
      };
    } else {
      return null;
    }
  };

  const logout = () => {
    localStorage.clear();
    setUserDetails(undefined);
    setRoles(undefined);
    window.location.reload();
  };

  // Configure the client to use Bearer token when authenticated
  AuthenticatedClient.interceptors.request.use(config => {
    const token = getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token.accessToken}`;
      config.headers["x-id-token"] = token.idToken;
    }

    return config;
  });

  // Logout prospect if token is invalid
  AuthenticatedClient.interceptors.response.use(
    response => response,
    error => {
      if (error.response?.status === 401 || error.response?.status === 403) {
        logout();
      }

      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (authState?.isAuthenticated) {
      getUserInfo().then(setUserDetails).catch(console.error);
    }
  }, [authState]);

  useEffect(() => {
    if (userDetails?.authorities) {
      setRoles(userDetails.authorities.map(({ authority }) => authority) as UserRole[]);
    }
  }, [userDetails?.authorities]);

  return <UserContext.Provider value={{ userDetails, roles, logout }} {...props} />;
};

const useUser = (): UserContextType => {
  const context: UserContextType | undefined = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return context;
};

export { UserProvider, useUser };
